import * as React from "react"
import DefaultLayout from "../templates/DefaultLayout"
import Banner from "../components/HomePage/Banner"
import DemoCTA from "../components/DemoCTA/DemoCTA"
import CurrentProducts from "../components/HomePage/CurrentProducts"
import NextProducts from "../components/HomePage/NextProducts"
import LawliteFeature from "../components/HomePage/LawliteFeature"
import Discover from "../components/HomePage/Discover"
import Subscribe from "../components/HomePage/Subscribe"
import NewsAndUpdates from "../components/HomePage/NewsAndUpdates"
import Seo from "../components/seo"
import FreeEbookCTA from "../components/HomePage/FreeEbookCTA"

const IndexPage = () => (
  <DefaultLayout>
    <Seo />
    {/* <DemoCTA /> */}
    <Banner />
    <FreeEbookCTA />
    <div id="products" />
    <CurrentProducts />
    <DemoCTA />
    <NextProducts />

    <NewsAndUpdates />
    <Subscribe />
    <LawliteFeature />
    <Discover />
    <DemoCTA />
  </DefaultLayout>
)

export default IndexPage
