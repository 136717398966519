import { Link } from "gatsby"
import React, { ReactElement } from "react"
import { nextProductsList } from "../../constants/products"
import { GetStarted } from "./Banner"

export default function NextProducts(): ReactElement {
  return (
    <div className="flex items-center justify-center text-center text-br-primary-blue my-10 flex-col px-8">
      <h1 className="text-2xl md:text-4xl">Next to Come</h1>
      {nextProductsList.map(product => (
        <React.Fragment key={product.name}>
          <Link to={product.link} className="hover:underline">
            <h3 className="text-xl md:text-3xl font-bold mt-12">
              {product.name}
            </h3>
          </Link>
          <img src={product.image} alt={product.name} />
          <p
            className="text-lg md:text-2xl mt-2"
            dangerouslySetInnerHTML={{ __html: product.description }}
          ></p>
          {/* <Link
            to={product.link}
            className="!no-underline font-black text-lg md:text-xl"
          >
            Learn More.
          </Link> */}
        </React.Fragment>
      ))}
      <div id="updates" />
    </div>
  )
}
