import React, { ReactElement } from "react"
import { ButtonLink } from "../Button/Button"

interface Props {}

export default function FreeEbookCTA({}: Props): ReactElement {
  return (
    <div className="flex justify-center bg-br-primary-blue text-center items-center py-6 flex-col  flex-wrap">
      <h2 className="text-white font-bold mr-4  text-2xl md:text-3xl mb-2">
        Free Legal Practice Playbook
      </h2>
      <h3 className="text-white font-bold mr-4 text-lg md:text-xl mb-3">
        "10 Keys to Systematically Grow your Legal Practice"
      </h3>
      <ButtonLink to="/e-book" invert className="text-lg">
        Download
      </ButtonLink>
    </div>
  )
}
